import { navigate } from 'gatsby';
import * as he from 'he';
import { upperFirst, camelCase, merge, keys } from 'lodash';
import * as moment from 'moment';
import URLSearchParams from 'core-js-pure/features/url-search-params';

export const KNOWLEDGE_CENTER_URL = '/knowledge-center';

export const KNOWLEDGE_CENTER_TYPES = {
    WpAward: {
        postType: 'Awards',
        slug: 'awards'
    },
    WpCaseStudy: {
        postType: 'Case Study',
        slug: 'case-study'
    },
    WpEvent: {
        postType: 'Training & Events',
        slug: 'training-events'
    },
    WpPost: {
        postType: 'News & Perspectives',
        slug: 'news'
    }
};

export const knowledgeCenterPostType = (typename) => {
    if (KNOWLEDGE_CENTER_TYPES.hasOwnProperty(typename)) {
        return KNOWLEDGE_CENTER_TYPES[typename].postType;
    }

    return typename;
};

export const normalizePath = (url) => {
    const reply = new URL(url, process.env.GATSBY_CMS_BASE_URL);

    if (reply.origin === new URL(process.env.GATSBY_CMS_BASE_URL).origin) {
        return reply.pathname === '/'
            ? reply.pathname
            : reply.pathname.replace(/\/$/, '');
    } else {
        return url;
    }
};

export const scale =
    (fromMin, fromMax, toMin = 0, toMax = 100) =>
    (number) =>
        ((toMax - toMin) * (number - fromMin)) / (fromMax - fromMin) + toMin;

export const simplifyMenu = ({ childItems, label, url, ...item }) => ({
    ...item,
    label: he.decode(label),
    childItems: childItems ? childItems.nodes.map(simplifyMenu) : [],
    url: url ? normalizePath(url) : null
});

export const transition = (
    { speed = 200, easingFunction = 'ease-in-out', delay = 0 },
    ...transitionProperties
) => {
    const properties = [...transitionProperties];

    if (properties.length) {
        const transitions = properties.map(
            (property) => `${property} ${speed}ms ${easingFunction} ${delay}ms`
        );

        return {
            transition: transitions.join(', ')
        };
    }

    return null;
};

export const blockNameTransform = (name) => {
    let type, block;
    [type, block] = name.split('/');

    block = upperFirst(camelCase(block));

    return `${type}/${block}Block`;
};

export const getImageUrl = (sizes, mediaItemUrl, sizeWanted) => {
    if (sizes && sizeWanted) {
        const twoX = sizes.find(({ name }) => name === `${sizeWanted}-2x`);

        if (twoX) {
            return twoX.sourceUrl;
        }

        const oneX = sizes.find(({ name }) => name === sizeWanted);

        if (oneX) {
            return oneX.sourceUrl;
        }
    }

    if (mediaItemUrl) {
        return mediaItemUrl;
    }

    return null;
};

// Returns an array of posts from an array of categories, removes duplicates
export const getPostsFromCategories = (categories) =>
    categories
        ? categories
              .reduce((acc, value) => acc.concat(value.posts.nodes), [])
              .reduce((acc, value) => {
                  const check = acc.find((item) => item.id === value.id);
                  return check ? acc : acc.concat([value]);
              }, [])
        : [];

export const getCategoryLabels = (categories) =>
    categories.map(({ label }) => label).join(', ');

//Takes a number and converts it to a USD currency string (1 -> $1.00)
export const convertNumberToPriceString = (number) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(number);

export const getDisplayDate = (start, end) => {
    const momentStart = moment(start);
    const momentEnd = moment(end);

    if (momentEnd.isSameOrBefore(momentStart, 'day')) {
        return momentStart.format('M/D/YY');
    }

    if (momentEnd.isAfter(momentStart, 'day')) {
        return `${momentStart.format('M/D/YY')} - ${momentEnd.format(
            'M/D/YY'
        )}`;
    }
};

export const getDisplayTime = (start, end) =>
    `${moment(start).format('h:mma')} - ${moment(end).format('h:mma')}`;

export const personalizeSchema = (rawSchema, replacements) => ({
    ...rawSchema,
    fieldsets: rawSchema.fieldsets.map(({ fields, ...fieldset }) => ({
        ...fieldset,
        fields: fields.map(
            ({ initialValue, initialValues, name, value, ...field }) => {
                const initial = Array.isArray(replacements[name])
                    ? {
                          initialValues: replacements[name] || initialValues
                      }
                    : {
                          initialValue: replacements[name] || initialValue
                      };

                return {
                    ...field,
                    initialValue,
                    initialValues,
                    ...initial,
                    name,
                    value: replacements[name] || value
                };
            }
        )
    }))
});

export const sortNames = (personOne, personTwo) => {
    const { firstName: firstNameOne, lastName: lastNameOne } = personOne;
    const { firstName: firstNameTwo, lastName: lastNameTwo } = personTwo;

    if (lastNameOne < lastNameTwo) return -1;
    if (lastNameOne > lastNameTwo) return 1;

    if (firstNameOne < firstNameTwo) return -1;
    if (firstNameOne > firstNameTwo) return 1;

    return 0;
};

export const stringStartsAndEndsWithQuotes = (str) => {
    if (
        (str.indexOf("'") === 0 && str.lastIndexOf("'") === str.length - 1) ||
        (str.indexOf('"') === 0 && str.lastIndexOf('"') === str.length - 1)
    ) {
        return true;
    } else {
        return false;
    }
};

export const isBrowser = typeof window !== 'undefined';

export const upsertOption = (obj, source) => merge({}, obj, source);

export const searchSite = async (queryParams = {}, currentPage = false) => {
    if (!isBrowser) return;

    const query = new URLSearchParams();
    keys(queryParams).forEach((param) => {
        query.set(param, queryParams[param]);
    });
    if (currentPage) {
        await navigate(`?${query.toString()}`);
    } else {
        await navigate(`/search?${query.toString()}`);
    }
};
