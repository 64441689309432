import buildTheme from '~/rivers-components/theme/build';

import { APPEARANCES } from '~/theme/constants';
import selection from '~/theme/icomoon/selection.json';
import { transition } from '~/utilities/utilityFunctions';

export const palette = {
    background: '#F7F7F7',
    black: '#000000',
    blue: '#0572C7',
    blueFaded: 'rgba(5, 114, 199, .44)',
    blueGray: '#666D85',
    borderGray: '#D8D8D8',
    brandBlue1: '#164577',
    brandBlue2: '#24ABE2',
    brandBlue3: '#0572c7',
    brandBlue4: '#18133C',
    darkGray: '#858484',
    warmGray: '#D6D1CA',
    jade: '#00a78a',
    lemon: '#ffc000',
    lightGray: '#EEEEEE',
    midnight: '#000824',
    midnightWithOpacity: 'rgba(0, 8, 36, .93)',
    rose: '#e54568',
    roseHighlight: 'rgba(229, 69, 104, .5)',
    green: '#02A78A',
    yellow: '#FEBF10',
    burntYellow: '#DFA501',
    burntYellow2: '#BD9600',
    lightViolet: '#E8ECF2',
    tableHeader: '#DEDEDE',
    text: '#343741',
    transparent: 'transparent',
    white: '#FFFFFF'
};

export const colors = {
    textColor: palette.text,
    headerBackground: palette.white,
    headerTopBackground: palette.lightGray,
    headerBottomBorder: palette.darkGray,
    headerBottomBackgroundMobile: palette.midnight,
    headerBottomBackgroundDesktop: palette.white,
    subMenuBackground: palette.midnight
};

export const gradients = {
    imageOverlay: `linear-gradient(to right, ${palette.midnight},  rgba(0, 0, 0, 0) 50%)`,
    arrowImageOverlay: `linear-gradient(to right, ${palette.midnight} 18%, rgba(0,0,0,0.00) 63%)`
};

export const fontFamily = {
    primary: '"Work Sans", sans-serif;'
};

export const fontFamilies = {
    sanSerif: '"Work Sans", sans-serif',
    serif: '"Palatino Linotype", "Book Antiqua", serif'
};

export const spacer = {
    gridGap: 45,
    gridGapMobile: 25,
    smallGridGap: 15,
    magnitude: 24,
    paragraph: 30,
    section: 70,
    sectionMobile: 45,
    unit: 'px'
};

export const containerPadding = {
    mobile: 25,
    tablet: 45,
    desktop: 70,
    unit: 'px'
};

export const containerWidths = {
    small: 900,
    large: 1090,
    unit: 'px'
};

export const maxImageSizes = {
    fullWidth: '1250px',
    graphic: '700px'
};

export const breakPointNumbers = {
    xs: 375,
    xsMax: 374,
    sm: 425,
    smMax: 424,
    md: 768,
    mdMax: 767,
    lg: 1024,
    lgMax: 1023,
    xl: 1280,
    xlMax: 1280
};

export const screenSizes = {
    xs: `${breakPointNumbers.xs}px`,
    xsMax: `${breakPointNumbers.xsMax}px`,
    sm: `${breakPointNumbers.sm}px`,
    smMax: `${breakPointNumbers.smMax}px`,
    md: `${breakPointNumbers.md}px`,
    mdMax: `${breakPointNumbers.mdMax}px`,
    lg: `${breakPointNumbers.lg}px`,
    lgMax: `${breakPointNumbers.lgMax}px`,
    xl: `${breakPointNumbers.xl}px`,
    xlMax: `${breakPointNumbers.xlMax}px`
};

export const breakpoints = {
    menuBreakpoint: screenSizes.lg,
    menuBreakpointMax: screenSizes.lgMax
};

export const button = {
    shared: {
        'borderRadius': '7px',
        'borderStyle': 'none',
        'fontWeight': 600,
        'fontSize': '1.6rem',
        'padding': '15px 25px',
        'textDecoration': 'none',
        ...transition({}, 'background-color'),
        '@media(min-width: 768px)': {
            fontSize: '2rem',
            padding: '18px 32px'
        }
    },
    [APPEARANCES.PRIMARY]: {
        'backgroundColor': palette.brandBlue3,
        'color': palette.white,
        ':not(:disabled):focus, :not(:disabled):hover': {
            backgroundColor: palette.rose
        }
    },
    [APPEARANCES.SECONDARY]: {
        'backgroundColor': palette.rose,
        'color': palette.white,
        ':not(:disabled):focus, :not(:disabled):hover': {
            backgroundColor: palette.brandBlue3
        }
    },
    [APPEARANCES.WHITE]: {
        'backgroundColor': palette.white,
        'color': palette.brandBlue1,
        ':not(:disabled):focus, :not(:disabled):hover': {
            backgroundColor: palette.rose,
            color: palette.white
        }
    },
    disabled: {
        backgroundColor: palette.blueGray,
        color: palette.white
    }
};

export const link = {
    shared: {
        textDecoration: 'underline'
    },
    [APPEARANCES.PRIMARY]: {
        color: palette.text
    }
};

export const portal = {
    offColor: palette.background,
    sectionColors: {
        brandBlue1: palette.brandBlue1,
        brandBlue2: palette.brandBlue2,
        brandBlue3: palette.brandBlue3,
        jade: palette.jade,
        rose: palette.rose,
        lemon: palette.lemon
    }
};

export const icomoon = {
    color: 'currentColor',
    selection,
    size: '1em'
};

export const modal = {
    close: {
        backgroundColor: 'inherit',
        color: 'inherit'
    },
    header: {
        backgroundColor: 'inherit'
    },
    body: {
        backgroundColor: 'inherit'
    },
    footer: {
        backgroundColor: 'inherit'
    },
    inner: {
        backgroundColor: 'none'
    },
    backdrop: {
        backgroundColor: palette.midnightWithOpacity,
        zIndex: 100
    },

    popoutWidth: screenSizes.md,
    verticalOffsetMin: '5%'
};

export default buildTheme({
    palette,
    colors,
    containerPadding,
    containerWidths,
    fontFamily,
    fontFamilies,
    gradients,
    icomoon,
    link,
    maxImageSizes,
    portal,
    spacer,
    screenSizes,
    breakPointNumbers,
    breakpoints,
    button,
    modal
});
