import {
    APPEARANCES as appearances,
    SIZES
} from '~/rivers-components/theme/constants';

export const APPEARANCES = {
    ...appearances,
    SECONDARY: 'secondary',
    WHITE: 'white'
};

export { SIZES };
