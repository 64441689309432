import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import THEME from './src/theme';

import { ScrollProvider } from './src/context/ScrollContext';
import { GravityFormsProvider } from './src/context/GravityFormsContext';

export const wrapRootElement = ({ element }) => {
    return (
        <ScrollProvider>
            <ParallaxProvider>
                <GravityFormsProvider>
                    <ThemeProvider theme={THEME}>{element}</ThemeProvider>
                </GravityFormsProvider>
            </ParallaxProvider>
        </ScrollProvider>
    );
};
