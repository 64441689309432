export const APPEARANCES = {
    NEUTRAL: 'neutral',
    PRIMARY: 'primary',
    INFO: 'info',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning'
};

export const SIZES = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small'
};
