import chroma from 'chroma-js';

import { APPEARANCES } from '~/rivers-components/theme/constants';
import selection from '~/rivers-components/theme/icomoon/selection.json';
import { expandBaseColor } from '~/rivers-components/theme/utilities';

export const palette = {
    [APPEARANCES.NEUTRAL]: expandBaseColor('#808080'),
    [APPEARANCES.PRIMARY]: expandBaseColor(chroma.hsl((360 / 6) * 4, 0.5, 0.5)),
    [APPEARANCES.INFO]: expandBaseColor(chroma.hsl((360 / 6) * 3, 0.5, 0.5)),
    [APPEARANCES.SUCCESS]: expandBaseColor(chroma.hsl((360 / 6) * 2, 0.5, 0.5)),
    [APPEARANCES.DANGER]: expandBaseColor(chroma.hsl((360 / 6) * 1, 0.5, 0.5)),
    [APPEARANCES.WARNING]: expandBaseColor(chroma.hsl((360 / 6) * 6, 0.5, 0.5))
};

export const fontFamilies = {
    sanSerif: 'Tahoma, Geneva, sans-serif',
    serif: "'Palatino Linotype', 'Book Antiqua', serif"
};

export const screenSizes = {
    sm: '425px',
    md: '768px',
    lg: '1024px'
};

export const spacer = {
    gridGap: 45,
    gridGapMobile: 25,
    magnitude: 24,
    section: 70,
    sectionMobile: 45,
    unit: 'px'
};

export const button = {
    shared: {
        borderRadius: '7px',
        borderStyle: 'solid',
        borderWidth: '2px',
        padding: '0.25em 0.5em',
        textDecoration: 'none'
    },
    [APPEARANCES.PRIMARY]: {
        borderColor: palette.primary.shade,
        backgroundColor: palette.primary.tint,
        color: palette.primary.contrast
    },
    [APPEARANCES.INFO]: {
        borderColor: palette.info.shade,
        backgroundColor: palette.info.tint,
        color: palette.info.contrast
    },
    [APPEARANCES.SUCCESS]: {
        borderColor: palette.success.shade,
        backgroundColor: palette.success.base,
        color: palette.success.contrast
    },
    [APPEARANCES.DANGER]: {
        borderColor: palette.danger.shade,
        backgroundColor: palette.danger.base,
        color: palette.danger.contrast
    },
    [APPEARANCES.WARNING]: {
        borderColor: palette.warning.shade,
        backgroundColor: palette.warning.base,
        color: palette.warning.contrast
    },
    disabled: {
        borderColor: palette.neutral.shade,
        backgroundColor: palette.neutral.tint,
        color: palette.neutral.contrast
    }
};

export const form = {
    control: {
        shared: {
            error: {},
            input: {},
            inputHeightPx: 50,
            label: {},
            labelText: {}
        }
    },

    form: {},

    schemaForm: {}
};

export const heading = {
    shared: {
        marginTop: 0
    },

    h1: {
        fontSize: '3rem',
        fontWeight: 700,
        lineHeight: 2.175,
        [`@media (min-width: ${screenSizes.lg})`]: {
            fontSize: '4rem'
        }
    },

    h2: {
        fontSize: '1.8rem',
        fontWeight: 700,
        lineHeight: 1.2,
        [`@media (min-width: ${screenSizes.lg})`]: {
            fontSize: '2.6rem'
        }
    },

    h3: {
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.25
    },

    h4: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.25
    }
};

export const icomoon = {
    color: 'currentColor',
    selection,
    size: '1em'
};

export const layout = {
    column: {
        styles: {}
    },

    floatingSection: {
        options: {
            withBorder: {
                borderBottom: `1px solid ${palette[APPEARANCES.NEUTRAL].shade}`
            }
        },
        styles: {}
    },

    row: {
        styles: {}
    }
};

export const link = {
    shared: {
        textDecoration: 'underline'
    },
    [APPEARANCES.PRIMARY]: {
        color: palette.primary.base
    },
    [APPEARANCES.INFO]: {
        color: palette.info.base
    },
    [APPEARANCES.SUCCESS]: {
        color: palette.success.base
    },
    [APPEARANCES.DANGER]: {
        color: palette.danger.base
    },
    [APPEARANCES.WARNING]: {
        color: palette.warning.base
    }
};

export const modal = {
    close: {
        backgroundColor: 'inherit',
        color: 'inherit'
    },
    header: {
        backgroundColor: 'cornflowerblue'
    },
    body: {
        backgroundColor: 'inherit'
    },
    footer: {
        backgroundColor: 'antiquewhite'
    },
    inner: {
        backgroundColor: 'plum',
        margin: '100px auto',
        maxWidth: '900px',
        width: '100%'
    },
    backdrop: {
        backgroundColor: 'chartreuse',
        padding: '0',
        zIndex: 1000,
        [`@media (min-width: ${screenSizes.lg})`]: {
            padding: '0 50px'
        }
    },

    popoutWidth: '640px',
    verticalOffsetMin: '10%'
};

export const paragraph = {};

export const table = {
    caption: {},
    table: {
        width: '100%'
    },
    tbody: {},
    tbodyStripe: {
        backgroundColor: 'rgba(201, 201, 201, 0.25)'
    },
    td: {
        padding: '3px 6px'
    },
    tfoot: {},
    th: {
        fontWeight: 'bold'
    },
    thead: {},
    tr: {}
};
