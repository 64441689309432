import chroma from 'chroma-js';
import { readableColor } from 'polished';

export const expandBaseColor = (
    base,
    { shadeAmount = 1, tintAmount = 1 } = {}
) => {
    const color = chroma(base);

    return {
        base: color.hex(),
        contrast: readableColor(color.hex()),
        shade: color.darken(shadeAmount).hex(),
        tint: color.brighten(tintAmount).hex()
    };
};
