import React, { useContext, createContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const GravityFormsContext = createContext();

export const GravityFormsProvider = ({ children }) => {
    const {
        allWpGfForm: { nodes: forms }
    } = useStaticQuery(graphql`
        query GravityFormQuery {
            allWpGfForm {
                nodes {
                    ...GravityFormFields
                }
            }
        }
    `);

    return (
        <GravityFormsContext.Provider value={{ forms }}>
            {children}
        </GravityFormsContext.Provider>
    );
};

export const useGravityFormsContext = () => {
    const context = useContext(GravityFormsContext);
    if (context === undefined) {
        throw new Error(
            'useGravityFormsContext must be used inside a GravityFormsProvider'
        );
    }
    return context;
};
