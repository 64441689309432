import React, { useContext, useState, createContext } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [currentSection, setCurrentSection] = useState('');

    return (
        <ScrollContext.Provider
            value={{
                currentSection,
                setCurrentSection
            }}
        >
            {children}
        </ScrollContext.Provider>
    );
};

export const useScrollContext = () => {
    const context = useContext(ScrollContext);

    if (context === undefined) {
        throw new Error(
            'useScrollContext must be used within a OptionsProvider'
        );
    }

    return context;
};
