import { config } from '@fortawesome/fontawesome-svg-core';
import './style.css';

config.autoAddCss = false;

let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
    nextRoute = location.pathname;
};

export const shouldUpdateScroll = ({ routerProps }) => {
    if (routerProps.location.state) {
        const { disableScrollUpdate } = routerProps.location.state;
        return !disableScrollUpdate;
    }
};

if (typeof window !== `undefined`) {
    window.addEventListener('unhandledrejection', function (event) {
        if (/loading chunk \d* failed./i.test(event.reason)) {
            if (nextRoute) {
                window.location.pathname = nextRoute;
            }
        }
    });

    if (!window.crypto) {
        window.crypto = window.msCrypto;
    }
}

export { wrapRootElement } from './gatsby-ssr';
