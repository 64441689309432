exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blocks-js": () => import("./../../../src/pages/blocks.js" /* webpackChunkName: "component---src-pages-blocks-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-award-js": () => import("./../../../src/templates/award.js" /* webpackChunkName: "component---src-templates-award-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-community-day-js": () => import("./../../../src/templates/communityDay.js" /* webpackChunkName: "component---src-templates-community-day-js" */),
  "component---src-templates-cybersecurity-alert-js": () => import("./../../../src/templates/cybersecurityAlert.js" /* webpackChunkName: "component---src-templates-cybersecurity-alert-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

