import circleAssign from 'circle-assign';

import * as RAW_THEME from '~/rivers-components/theme';

const buildSpacing =
    ({ magnitude, unit }) =>
    (amount = 1) =>
        `${amount * magnitude}${unit}`;

const build = (appTheme) => {
    const context = circleAssign({}, RAW_THEME, appTheme);

    return {
        ...context,
        spacing: buildSpacing(context.spacer)
    };
};

export default build;
